// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";

// const LoginPage = () => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [error, setError] = useState("");
//   const [success, setSuccess] = useState(false);
//   const { t, i18n } = useTranslation();

//   const navigate = useNavigate();

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     setError("");
//     setSuccess(false);

//     // Static validation
//     if (!email || !password) {
//       setError("Please fill in all fields");
//       return;
//     }

//     // Demo credentials
//     if (email === "batman@gmail.com" && password === "iambatman") {
//       setSuccess(true);
//       setEmail("");
//       setPassword("");
//       navigate("/home");
//     } else {
//       setError("Invalid credentials");
//     }
//   };

//   return (
//     <div
//       style={{
//         minHeight: "100vh",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         backgroundColor: "#f8fafc",
//         padding: "16px",
//       }}
//     >
//       <div
//         style={{
//           width: "100%",
//           maxWidth: "400px",
//           border: "1px solid #e5e7eb",
//           borderRadius: "8px",
//           padding: "16px",
//           backgroundColor: "#fff",
//         }}
//       >
//         <h2
//           style={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
//         >
//           Login
//         </h2>
//         <form onSubmit={handleSubmit} style={{ marginTop: "16px" }}>
//           <div style={{ marginBottom: "16px" }}>
//             <div style={{ position: "relative" }}>
//               <input
//                 type="email"
//                 placeholder="Email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 style={{
//                   width: "100%",
//                   padding: "12px 12px 12px 40px",
//                   borderRadius: "4px",
//                   border: "1px solid #d1d5db",
//                 }}
//               />
//               <span
//                 style={{
//                   position: "absolute",
//                   left: "12px",
//                   top: "50%",
//                   transform: "translateY(-50%)",
//                   color: "#9ca3af",
//                 }}
//               >
//                 📧
//               </span>
//             </div>
//           </div>
//           <div style={{ marginBottom: "16px" }}>
//             <div style={{ position: "relative" }}>
//               <input
//                 type="password"
//                 placeholder="Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 style={{
//                   width: "100%",
//                   padding: "12px 12px 12px 40px",
//                   borderRadius: "4px",
//                   border: "1px solid #d1d5db",
//                 }}
//               />
//               <span
//                 style={{
//                   position: "absolute",
//                   left: "12px",
//                   top: "50%",
//                   transform: "translateY(-50%)",
//                   color: "#9ca3af",
//                 }}
//               >
//                 🔒
//               </span>
//             </div>
//           </div>

//           {error && (
//             <div
//               style={{
//                 backgroundColor: "#fee2e2",
//                 color: "#b91c1c",
//                 padding: "8px",
//                 borderRadius: "4px",
//                 marginBottom: "16px",
//               }}
//             >
//               {error}
//             </div>
//           )}

//           {success && (
//             <div
//               style={{
//                 backgroundColor: "#d1fae5",
//                 color: "#065f46",
//                 padding: "8px",
//                 borderRadius: "4px",
//                 marginBottom: "16px",
//               }}
//             >
//               Successfully logged in!
//             </div>
//           )}

//           <button
//             type="submit"
//             style={{
//               width: "100%",
//               padding: "12px",
//               borderRadius: "4px",
//               border: "none",
//               backgroundColor: "#3b82f6",
//               color: "#fff",
//               fontWeight: "bold",
//             }}
//           >
//             Sign In
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default LoginPage;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Navbar from "../components/Header";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation(); // Import the `t` function

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);

    // Static validation
    if (!email || !password) {
      setError(t("Please fill in all fields"));
      return;
    }

    // Demo credentials
    if (email === "batman@gmail.com" && password === "iambatman") {
      setSuccess(true);
      setEmail("");
      setPassword("");
      navigate("/home");
    } else {
      setError(t("Invalid credentials"));
    }
  };

  return (
    <>
      <Navbar />
      <div
        style={{
          minHeight: "90vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f8fafc",
          padding: "16px",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "400px",
            border: "1px solid #e5e7eb",
            borderRadius: "8px",
            padding: "16px",
            backgroundColor: "#fff",
          }}
        >
          <h2
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {t("Login")}
          </h2>
          <form onSubmit={handleSubmit} style={{ marginTop: "16px" }}>
            <div style={{ marginBottom: "16px" }}>
              <div style={{ position: "relative" }}>
                <input
                  type="email"
                  placeholder={t("Email")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "12px 12px 12px 40px",
                    borderRadius: "4px",
                    border: "1px solid #d1d5db",
                  }}
                />
                <span
                  style={{
                    position: "absolute",
                    left: "12px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#9ca3af",
                  }}
                >
                  📧
                </span>
              </div>
            </div>
            <div style={{ marginBottom: "16px" }}>
              <div style={{ position: "relative" }}>
                <input
                  type="password"
                  placeholder={t("Password")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "12px 12px 12px 40px",
                    borderRadius: "4px",
                    border: "1px solid #d1d5db",
                  }}
                />
                <span
                  style={{
                    position: "absolute",
                    left: "12px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#9ca3af",
                  }}
                >
                  🔒
                </span>
              </div>
            </div>

            {error && (
              <div
                style={{
                  backgroundColor: "#fee2e2",
                  color: "#b91c1c",
                  padding: "8px",
                  borderRadius: "4px",
                  marginBottom: "16px",
                }}
              >
                {error}
              </div>
            )}

            {success && (
              <div
                style={{
                  backgroundColor: "#d1fae5",
                  color: "#065f46",
                  padding: "8px",
                  borderRadius: "4px",
                  marginBottom: "16px",
                }}
              >
                {t("Successfully logged in!")}
              </div>
            )}

            <button
              type="submit"
              style={{
                width: "100%",
                padding: "12px",
                borderRadius: "4px",
                border: "none",
                backgroundColor: "#3b82f6",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              {t("Sign In")}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
